@import url("https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700");

@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,400i,700");

@import "~nouislider/distribute/nouislider";

@import "~magnific-popup/dist/magnific-popup";

@import "directory-2-0/swiper.min.css";

@import "directory-2-0/daterangepicker.css";

// @import "directory-2-0/css/style.default";

@import "directory-2-0/css/custom";

@import url("https://use.fontawesome.com/releases/v5.8.1/css/all.css");
